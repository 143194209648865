<!-- Sidebar start here... -->
<div class="main-side-content" *ngIf="showNavigation">
  <div class="side-content">
    <!-- Profile-content start here... -->
    <div class="dropdown">
      <button class="btn btn-profile dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
        <span>
          <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
            <circle cx="16" cy="16" r="16" fill="url(#paint0_linear_133_1354)" />
            <defs>
              <linearGradient id="paint0_linear_133_1354" x1="16" y1="0" x2="16" y2="32" gradientUnits="userSpaceOnUse">
                <stop stop-color="#0035FF" />
                <stop offset="1" stop-color="#002099" />
              </linearGradient>
            </defs>
          </svg>
        </span>
        <p class="mb-0 ng-star-inserted">
          {{ selectedProject?.projectName || "" }}
        </p>

        <span class="ms-auto">
          <svg xmlns=" http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <path
              d="M10 16C10.4142 16 10.75 15.6642 10.75 15.25C10.75 14.8358 10.4142 14.5 10 14.5C9.58579 14.5 9.25 14.8358 9.25 15.25C9.25 15.6642 9.58579 16 10 16Z"
              stroke="#666D80" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path
              d="M10 10.75C10.4142 10.75 10.75 10.4142 10.75 10C10.75 9.58579 10.4142 9.25 10 9.25C9.58579 9.25 9.25 9.58579 9.25 10C9.25 10.4142 9.58579 10.75 10 10.75Z"
              stroke="#666D80" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path
              d="M10 5.5C10.4142 5.5 10.75 5.16421 10.75 4.75C10.75 4.33579 10.4142 4 10 4C9.58579 4 9.25 4.33579 9.25 4.75C9.25 5.16421 9.58579 5.5 10 5.5Z"
              stroke="#666D80" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
        </span>
      </button>
      <ul class="dropdown-menu dropdown-menu-end" *ngIf="isAdmin">
        <li>
          <a class="dropdown-item" href="javascript:void(0);">Manage project</a>
        </li>
        <li>
          <a class="dropdown-item" [routerLink]="[
              '/dashboard/manage/edit-project/' + selectedProject?.id
            ]">Edit</a>
        </li>
        <li>
          <a class="dropdown-item" href="javascript:void(0);" (click)="openConfirmationModal()">Delete</a>
        </li>
      </ul>
    </div>
    <!-- Profile-content end here... -->

    <h5 class="title">Menu</h5>
    <ul class="list-unstyled d-flex align-items-start justify-content-start flex-column">
      <li>
        <a (click)="showMenu()" role="button" class="btn flow">
          <span>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
              <path
                d="M16 11.5H4C3.17157 11.5 2.5 12.1716 2.5 13V16C2.5 16.8284 3.17157 17.5 4 17.5H16C16.8284 17.5 17.5 16.8284 17.5 16V13C17.5 12.1716 16.8284 11.5 16 11.5Z"
                stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M5.5 14.5H5.50667" stroke="#000000" stroke-width="1.5" stroke-linecap="round"
                stroke-linejoin="round" />
              <path
                d="M16 2.5H4C3.17157 2.5 2.5 3.17157 2.5 4V7C2.5 7.82843 3.17157 8.5 4 8.5H16C16.8284 8.5 17.5 7.82843 17.5 7V4C17.5 3.17157 16.8284 2.5 16 2.5Z"
                stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M5.5 5.5H5.50667" stroke="#000000" stroke-width="1.5" stroke-linecap="round"
                stroke-linejoin="round" />
            </svg>
          </span>
          Create workflow
        </a>
      </li>
      <li>
        <a [routerLink]="selectedProject?.id ? ['/dashboard'] : null" role="button" class="btn"
          [class.active]="currentPath === '/dashboard'" [class.disabled]="!selectedProject?.id"
          [style.pointer-events]="!selectedProject?.id ? 'none' : 'auto'">
          <span>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
              <path d="M16.75 11.5H11.5V16.75H16.75V11.5Z" stroke="#666D80" stroke-width="1.5" stroke-linecap="round"
                stroke-linejoin="round" />
              <path d="M8.5 11.5H3.25V16.75H8.5V11.5Z" stroke="#666D80" stroke-width="1.5" stroke-linecap="round"
                stroke-linejoin="round" />
              <path d="M16.75 3.25H11.5V8.5H16.75V3.25Z" stroke="#666D80" stroke-width="1.5" stroke-linecap="round"
                stroke-linejoin="round" />
              <path d="M8.5 3.25H3.25V8.5H8.5V3.25Z" stroke="#666D80" stroke-width="1.5" stroke-linecap="round"
                stroke-linejoin="round" />
            </svg>
          </span>
          Dashboard
        </a>
      </li>
      <li>
        <a [routerLink]="selectedProject?.id ? ['/dashboard/manage/my-task'] : null"
          [class.active]="currentPath.includes('my-task')" role="button" class="btn"
          [class.disabled]="!selectedProject?.id" [style.pointer-events]="!selectedProject?.id ? 'none' : 'auto'">
          <span>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
              <path
                d="M11.5 2.5H5.5C5.10218 2.5 4.72064 2.65804 4.43934 2.93934C4.15804 3.22064 4 3.60218 4 4V16C4 16.3978 4.15804 16.7794 4.43934 17.0607C4.72064 17.342 5.10218 17.5 5.5 17.5H14.5C14.8978 17.5 15.2794 17.342 15.5607 17.0607C15.842 16.7794 16 16.3978 16 16V7L11.5 2.5Z"
                stroke="#666D80" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M13 13.75H7" stroke="#666D80" stroke-width="1.5" stroke-linecap="round"
                stroke-linejoin="round" />
              <path d="M13 10.75H7" stroke="#666D80" stroke-width="1.5" stroke-linecap="round"
                stroke-linejoin="round" />
              <path d="M8.5 7.75H7.75H7" stroke="#666D80" stroke-width="1.5" stroke-linecap="round"
                stroke-linejoin="round" />
              <path d="M11.5 2.5V7H16" stroke="#666D80" stroke-width="1.5" stroke-linecap="round"
                stroke-linejoin="round" />
            </svg>
          </span>
          My Tasks
        </a>
      </li>
    </ul>

    <div class="accordion" id="accordionPanelsStayOpen">
      <div class="accordion-item px-0 border-0">
        <h2 class="accordion-header">
          <button class="accordion-button title" type="button" data-bs-toggle="collapse"
            data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="true"
            aria-controls="panelsStayOpen-collapseOne">
            Manage
          </button>
        </h2>
        <div id="panelsStayOpen-collapseOne" class="accordion-collapse collapse show">
          <ul class="list-unstyled d-flex align-items-start justify-content-start flex-column">
            <li>
              <a [routerLink]=" ['/dashboard/config/forms']" [class.active]="currentPath.includes('forms')"
                role="button" class="btn" [class.disabled]="!selectedProject?.id"
                [style.pointer-events]="!selectedProject?.id ? 'none' : 'auto'">
                <span><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <path
                      d="M10.75 2.5H5.5C5.10218 2.5 4.72064 2.65804 4.43934 2.93934C4.15804 3.22064 4 3.60218 4 4V16C4 16.3978 4.15804 16.7794 4.43934 17.0607C4.72064 17.342 5.10218 17.5 5.5 17.5H14.5C14.8978 17.5 15.2794 17.342 15.5607 17.0607C15.842 16.7794 16 16.3978 16 16V7.75L10.75 2.5Z"
                      stroke="#666D80" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M10.75 2.5V7.75H16" stroke="#666D80" stroke-width="1.5" stroke-linecap="round"
                      stroke-linejoin="round" />
                  </svg></span>
                Form Templates
              </a>
            </li>
            <li>
              <a [routerLink]="['/dashboard/config/users']" [class.active]="currentPath.includes('users')" role="button"
                class="btn">
                <span><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <path
                      d="M18.25 16.75V15.25C18.2495 14.5853 18.0283 13.9396 17.621 13.4142C17.2138 12.8889 16.6436 12.5137 16 12.3475M13.75 16.75V15.25C13.75 14.4544 13.4339 13.6913 12.8713 13.1287C12.3087 12.5661 11.5456 12.25 10.75 12.25H4.75C3.95435 12.25 3.19129 12.5661 2.62868 13.1287C2.06607 13.6913 1.75 14.4544 1.75 15.25V16.75M13 3.3475C13.6453 3.51273 14.2173 3.88803 14.6257 4.41424C15.0342 4.94044 15.2559 5.58763 15.2559 6.25375C15.2559 6.91988 15.0342 7.56706 14.6257 8.09327C14.2173 8.61948 13.6453 8.99478 13 9.16M10.75 6.25C10.75 7.90685 9.40685 9.25 7.75 9.25C6.09315 9.25 4.75 7.90685 4.75 6.25C4.75 4.59315 6.09315 3.25 7.75 3.25C9.40685 3.25 10.75 4.59315 10.75 6.25Z"
                      stroke="#666D80" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                  </svg></span>
                Users and groups
              </a>
            </li>
            <li>
              <a [routerLink]="selectedProject?.id ? ['/dashboard/config/files/file-storage'] : null"
                [class.active]="currentPath.includes('file-storage')" [class.disabled]="!selectedProject?.id"
                role="button" class="btn" [style.pointer-events]="!selectedProject?.id ? 'none' : 'auto'">
                <span><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <path
                      d="M16.75 10C16.75 11.245 13.75 12.25 10 12.25C6.25 12.25 3.25 11.245 3.25 10M16.75 4.75C16.75 5.99264 13.7279 7 10 7C6.27208 7 3.25 5.99264 3.25 4.75M16.75 4.75C16.75 3.50736 13.7279 2.5 10 2.5C6.27208 2.5 3.25 3.50736 3.25 4.75M16.75 4.75V15.25C16.75 16.495 13.75 17.5 10 17.5C6.25 17.5 3.25 16.495 3.25 15.25V4.75"
                      stroke="#666D80" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                  </svg></span>
                File storage
              </a>
            </li>
            <li>
              <a [routerLink]="selectedProject?.id ? ['/dashboard/config/data-tables'] : null"
                [class.active]="currentPath.includes('data-tables')" [class.disabled]="!selectedProject?.id"
                role="button" class="btn" [style.pointer-events]="!selectedProject?.id ? 'none' : 'auto'">
                <span><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <path
                      d="M15.25 3.25H4.75C3.92157 3.25 3.25 3.92157 3.25 4.75V15.25C3.25 16.0784 3.92157 16.75 4.75 16.75H15.25C16.0784 16.75 16.75 16.0784 16.75 15.25V4.75C16.75 3.92157 16.0784 3.25 15.25 3.25Z"
                      stroke="#666D80" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M7.75 16.75V7.75" stroke="#666D80" stroke-width="1.5" stroke-linecap="round"
                      stroke-linejoin="round" />
                    <path d="M3.25 7.75H16.75" stroke="#666D80" stroke-width="1.5" stroke-linecap="round"
                      stroke-linejoin="round" />
                  </svg></span>
                Tables
              </a>
            </li>
            <li>
              <a [routerLink]="selectedProject?.id ? ['/dashboard/config/email-templates'] : null"
                [class.active]="currentPath.includes('email-templates')" [class.disabled]="!selectedProject?.id"
                role="button" class="btn" [style.pointer-events]="!selectedProject?.id ? 'none' : 'auto'">
                <span><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <path
                      d="M4 4H16C16.825 4 17.5 4.675 17.5 5.5V14.5C17.5 15.325 16.825 16 16 16H4C3.175 16 2.5 15.325 2.5 14.5V5.5C2.5 4.675 3.175 4 4 4Z"
                      stroke="#666D80" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M17.5 5.5L10 10.75L2.5 5.5" stroke="#666D80" stroke-width="1.5" stroke-linecap="round"
                      stroke-linejoin="round" />
                  </svg></span>
                Email Templates
              </a>
            </li>
            <li>
              <a [routerLink]="selectedProject?.id ? ['/dashboard/config/workflows'] : null"
                [class.active]="currentPath.includes('workflows')" [class.disabled]="!selectedProject?.id" role="button"
                class="btn" [style.pointer-events]="!selectedProject?.id ? 'none' : 'auto'">
                <span><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <path
                      d="M16 11.5H4C3.17157 11.5 2.5 12.1716 2.5 13V16C2.5 16.8284 3.17157 17.5 4 17.5H16C16.8284 17.5 17.5 16.8284 17.5 16V13C17.5 12.1716 16.8284 11.5 16 11.5Z"
                      stroke="#666D80" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M5.5 14.5H5.50667" stroke="#666D80" stroke-width="1.5" stroke-linecap="round"
                      stroke-linejoin="round" />
                    <path
                      d="M16 2.5H4C3.17157 2.5 2.5 3.17157 2.5 4V7C2.5 7.82843 3.17157 8.5 4 8.5H16C16.8284 8.5 17.5 7.82843 17.5 7V4C17.5 3.17157 16.8284 2.5 16 2.5Z"
                      stroke="#666D80" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M5.5 5.5H5.50667" stroke="#666D80" stroke-width="1.5" stroke-linecap="round"
                      stroke-linejoin="round" />
                  </svg></span>
                Workflow
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div class="accordion-item px-0 border-0">
        <h2 class="accordion-header">
          <button class="accordion-button collapsed title" type="button" data-bs-toggle="collapse"
            data-bs-target="#panelsStayOpen-collapseTwo" aria-expanded="false"
            aria-controls="panelsStayOpen-collapseTwo">
            Projects
          </button>
        </h2>
        <div id="panelsStayOpen-collapseTwo" class="accordion-collapse collapse">
          <ul class="list-unstyled d-flex align-items-start justify-content-start flex-column">
            <li *ngFor="let data of projects">
              <a href="javascript:void(0);" role="button" (click)="handleSelectProject(data)"
                [ngClass]="{ active: data.id === selectedProject.id }" class="btn">
                <span><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <path d="M13 14.5L17.5 10L13 5.5" stroke="#666D80" stroke-width="1.5" stroke-linecap="round"
                      stroke-linejoin="round" />
                    <path d="M7 5.5L2.5 10L7 14.5" stroke="#666D80" stroke-width="1.5" stroke-linecap="round"
                      stroke-linejoin="round" />
                  </svg></span>
                {{ data.projectName }}
              </a>
            </li>
            <li>
              <a [routerLink]="['/dashboard/manage/create-project']"
                [class.active]="currentPath.includes('create-project')" role="button" class="btn" *ngIf="isAdmin">
                <span><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <path d="M10 4.75V15.25" stroke="#666D80" stroke-width="1.5" stroke-linecap="round"
                      stroke-linejoin="round" />
                    <path d="M4.75 10H15.25" stroke="#666D80" stroke-width="1.5" stroke-linecap="round"
                      stroke-linejoin="round" />
                  </svg></span>
                Add more projects
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div class="accordion-item px-0 border-0">
        <h2 class="accordion-header">
          <button class="accordion-button collapsed title" type="button" data-bs-toggle="collapse"
            data-bs-target="#panelsStayOpen-collapseThree" aria-expanded="false"
            aria-controls="panelsStayOpen-collapseThree">
            Apps
          </button>
        </h2>
        <div id="panelsStayOpen-collapseThree" class="accordion-collapse collapse">
          <ul class="list-unstyled d-flex align-items-start justify-content-start flex-column">
            <li>
              <a href="javascript:void(0);" role="button" class="btn">
                <span><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <path
                      d="M12.625 15.25H11.5V16.375C11.5 16.9975 12.0025 17.5 12.625 17.5C13.2475 17.5 13.75 16.9975 13.75 16.375C13.75 15.7525 13.2475 15.25 12.625 15.25Z"
                      stroke="#666D80" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    <path
                      d="M8.125 11.5C8.7475 11.5 9.25 12.0025 9.25 12.625V16.375C9.25 16.9975 8.7475 17.5 8.125 17.5C7.5025 17.5 7 16.9975 7 16.375V12.625C7 12.0025 7.5025 11.5 8.125 11.5Z"
                      stroke="#666D80" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    <path
                      d="M3.625 11.5H4.75V12.625C4.75 13.2475 4.2475 13.75 3.625 13.75C3.0025 13.75 2.5 13.2475 2.5 12.625C2.5 12.0025 3.0025 11.5 3.625 11.5Z"
                      stroke="#666D80" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    <path
                      d="M11.5 11.875C11.5 11.2525 12.0025 10.75 12.625 10.75H16.375C16.9975 10.75 17.5 11.2525 17.5 11.875C17.5 12.4975 16.9975 13 16.375 13H12.625C12.0025 13 11.5 12.4975 11.5 11.875Z"
                      stroke="#666D80" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    <path
                      d="M8.5 8.125C8.5 7.5025 7.9975 7 7.375 7H3.625C3.0025 7 2.5 7.5025 2.5 8.125C2.5 8.7475 3.0025 9.25 3.625 9.25H7.375C7.9975 9.25 8.5 8.7475 8.5 8.125Z"
                      stroke="#666D80" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    <path
                      d="M16.375 8.5H15.25V7.375C15.25 6.7525 15.7525 6.25 16.375 6.25C16.9975 6.25 17.5 6.7525 17.5 7.375C17.5 7.9975 16.9975 8.5 16.375 8.5Z"
                      stroke="#666D80" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    <path
                      d="M11.875 8.5C11.2525 8.5 10.75 7.9975 10.75 7.375V3.625C10.75 3.0025 11.2525 2.5 11.875 2.5C12.4975 2.5 13 3.0025 13 3.625V7.375C13 7.9975 12.4975 8.5 11.875 8.5Z"
                      stroke="#666D80" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    <path
                      d="M7.375 4.75H8.5V3.625C8.5 3.0025 7.9975 2.5 7.375 2.5C6.7525 2.5 6.25 3.0025 6.25 3.625C6.25 4.2475 6.7525 4.75 7.375 4.75Z"
                      stroke="#666D80" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                  </svg></span>
                Slack
              </a>
            </li>
            <li>
              <a href="javascript:void(0);" role="button" class="btn">
                <span><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <path d="M10 4.75V15.25" stroke="#666D80" stroke-width="1.5" stroke-linecap="round"
                      stroke-linejoin="round" />
                    <path d="M4.75 10H15.25" stroke="#666D80" stroke-width="1.5" stroke-linecap="round"
                      stroke-linejoin="round" />
                  </svg></span>
                Add more apps
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div class="accordion-item px-0 border-0">
        <h2 class="accordion-header">
          <button class="accordion-button collapsed title" type="button" data-bs-toggle="collapse"
            data-bs-target="#panelsStayOpen-collapseForth" aria-expanded="false"
            aria-controls="panelsStayOpen-collapseForth">
            Other
          </button>
        </h2>
        <div id="panelsStayOpen-collapseForth" class="accordion-collapse collapse">
          <ul class="list-unstyled d-flex align-items-start justify-content-start flex-column">
            <li>
              <a [routerLink]="selectedProject?.id ? ['/dashboard/manage/chat'] : null"
                [class.active]="currentPath.includes('chat')" [class.disabled]="!selectedProject?.id" role="button"
                class="btn" [style.pointer-events]="!selectedProject?.id ? 'none' : 'auto'">
                <span><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <path
                      d="M16.75 9.62502C16.7526 10.6149 16.5213 11.5914 16.075 12.475C15.5458 13.5338 14.7323 14.4244 13.7256 15.047C12.7189 15.6696 11.5587 15.9996 10.375 16C9.3851 16.0026 8.40859 15.7713 7.525 15.325L3.25 16.75L4.675 12.475C4.2287 11.5914 3.99742 10.6149 4 9.62502C4.00046 8.44134 4.33046 7.28116 4.95304 6.27443C5.57562 5.26771 6.46619 4.4542 7.525 3.92502C8.40859 3.47872 9.3851 3.24744 10.375 3.25002H10.75C12.3133 3.33627 13.7898 3.99609 14.8969 5.10317C16.0039 6.21024 16.6638 7.68676 16.75 9.25002V9.62502Z"
                      stroke="#666D80" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                  </svg></span>
                Chat
              </a>
            </li>
            <li>
              <a href="javascript:void(0);" role="button" class="btn">
                <span><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <path
                      d="M10 17.5C14.1421 17.5 17.5 14.1421 17.5 10C17.5 5.85786 14.1421 2.5 10 2.5C5.85786 2.5 2.5 5.85786 2.5 10C2.5 14.1421 5.85786 17.5 10 17.5Z"
                      stroke="#666D80" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M10 13.75H10.0067" stroke="#666D80" stroke-width="1.5" stroke-linecap="round"
                      stroke-linejoin="round" />
                    <path
                      d="M7.81763 7.74994C7.99395 7.24869 8.34199 6.82602 8.80009 6.55679C9.25819 6.28756 9.7968 6.18914 10.3205 6.27897C10.8442 6.3688 11.3192 6.64108 11.6614 7.04758C12.0036 7.45409 12.1909 7.96858 12.1901 8.49994C12.1901 9.99994 9.94013 10.7499 9.94013 10.7499"
                      stroke="#666D80" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                  </svg></span>
                Help
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <!-- side-footer start here... -->
    <div class="side-footer">
      <ul class="list-unstyled d-flex align-items-start justify-content-start flex-column mb-0">
        <li class="position-relative">
          <a (click)="showcollabratorCard()" role="button" class="btn"><span class="userName"><svg
                xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                <circle cx="11" cy="11" r="10.5" fill="white" stroke="#BDC4D7" stroke-dasharray="2 2" />
                <path
                  d="M13 16V15C13 14.4696 12.7893 13.9609 12.4142 13.5858C12.0391 13.2107 11.5304 13 11 13H7.5C6.96957 13 6.46086 13.2107 6.08579 13.5858C5.71071 13.9609 5.5 14.4696 5.5 15V16M15 9.5V12.5M16.5 11H13.5M11.25 9C11.25 10.1046 10.3546 11 9.25 11C8.14543 11 7.25 10.1046 7.25 9C7.25 7.89543 8.14543 7 9.25 7C10.3546 7 11.25 7.89543 11.25 9Z"
                  stroke="#343B4E" stroke-linecap="round" stroke-linejoin="round" />
              </svg></span>
            Add collaborator
          </a>
        </li>
      </ul>
    </div>
    <!-- side-footer end here... -->
  </div>
  <!-- Sidebar end here... -->

  <!-- Add-New Collabrator content start here... -->
  <div class="collabrator-content" [class.show]="cardToshow">
    <!-- content head start here... -->
    <div class="card-head d-flex align-items-start justify-content-between gap-3 w-100">
      <div>
        <h4 class="title mb-0">Invite collaborators</h4>
        <p class="desc mb-0">
          New members will gain access to public Spaces, Docs and Dashboards.
        </p>
      </div>
      <button class="btn btn-cancel p-0 bg-transparent border-0" type="button" (click)="cardToshow = false">
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
          <path d="M14.5 5.5L5.5 14.5M5.5 5.5L14.5 14.5" stroke="black" stroke-width="1.5" stroke-linecap="round"
            stroke-linejoin="round" />
        </svg>
      </button>
    </div>
    <div class="form-group mb-3 w-100">
      <label for="addMail" class="form-label">E-mail address</label>
      <input type="email" class="form-control" id="addMail" placeholder="Enter your email address" />
    </div>
    <div class="btns w-100">
      <button class="btn btn-secondary justify-content-center w-100" (click)="cardToshow = false" type="button">
        Cancel
      </button>
      <button class="btn btn-primary justify-content-center w-100" type="button">
        Send Invite
      </button>
    </div>
  </div>
  <!-- Create new work-flow content end here... -->
</div>

<!-- side-content for workflow start here... -->
<div class="workflow-sidecontent" *ngIf="!createWorkFlow" [class.show]="menuToshow || !showNavigation"
  [class.config]="!showNavigation">
  <div class="head" [class.d-none]="!showNavigation">
    <h4 class="title mb-0">Let’s start to create workflow</h4>
    <button class="btn btn-cancel border-0" type="button" (click)="menuToshow = false">
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
        <path d="M14.5 5.5L5.5 14.5M5.5 5.5L14.5 14.5" stroke="black" stroke-width="1.5" stroke-linecap="round"
          stroke-linejoin="round" />
      </svg>
    </button>
  </div>
  <div class="head" [class.d-none]="showNavigation">
    <a [routerLink]="selectedProject?.id ? ['/dashboard/'] : ['/dashboard/manage/create-project']"
      class="btn btn-cancel border-0" role="button" (click)=" menuToshow">
      <svg xmlns="http://www.w3.org/2000/svg" width="19" height="13" viewBox="0 0 19 13" fill="none">
        <path d="M18 7H1" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M6.5 11.75L1.25 6.5L6.5 1.25" stroke="black" stroke-width="1.5" stroke-linecap="round"
          stroke-linejoin="round" />
      </svg>
    </a>
    <h4 class="title mb-0">Create workflow</h4>
  </div>
  <div class="content-body">
    <div class="cards">
      <div class="card border-0 rounded-0">
        <div class="card-body">
          <span>
            <svg width="11" height="14" viewBox="0 0 21 25" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M0.847229 3.18173V24.8659H17.699V21.7682H20.6728V0.0839844H3.82106V3.18173H0.847229ZM16.7077 23.8334H1.83851V4.21431H13.2382V7.82835H16.7077V23.8334ZM14.2295 4.94434L16.0068 6.79576H14.2295V4.94434ZM4.81234 1.11657H19.6815V20.7356H17.699V7.09831L13.939 3.18173H4.81234V1.11657Z"
                fill="#781DEE" />
              <path
                d="M3.82074 9.89364H14.7248V10.9262H3.82074V9.89364ZM3.82074 12.9914H14.7248V14.024H3.82074V12.9914ZM3.82074 16.0891H14.7248V17.1217H3.82074V16.0891ZM3.82074 20.2195H8.28149V21.252H3.82074V20.2195ZM10.264 20.2195H14.7248V21.252H10.264V20.2195ZM7.29021 6.7959H11.2553V7.82848H7.29021V6.7959Z"
                fill="#781DEE" />
            </svg>
          </span>
          <div>
            <a [routerLink]="selectedProject?.id ? ['/dashboard/config/forms'] : null"
              class="btn stretched-link p-0 border-0" [class.disabled]="!selectedProject?.id"
              [style.pointer-events]="!selectedProject?.id ? 'none' : 'auto'">
              <h4 class="title mb-0">Form instances</h4>
            </a>
            <p class="desc mb-0">
              Create form into pieces as instance of a form template
            </p>
          </div>
        </div>
      </div>
      <div class="card border-0 rounded-0">
        <div class="card-body">
          <span>
            <svg width="15" height="10" viewBox="0 0 28 21" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M23.8356 6.62858V6.62111C23.8356 4.49695 22.1137 2.77498 19.9895 2.77498H10.704L8.57047 1.0698C8.40003 0.933586 8.18834 0.859375 7.97015 0.859375H4.59919C2.47502 0.859375 0.753052 2.58135 0.753052 4.70551V16.2439C0.753052 18.3681 2.47502 20.09 4.59919 20.09H22.3881C23.6697 20.09 24.7974 19.2447 25.157 18.0147L27.4052 10.3224C27.9448 8.47591 26.5602 6.62858 24.6364 6.62858H23.8356ZM4.59919 2.78244H7.63313L9.76664 4.48763C9.93708 4.62384 10.1488 4.69805 10.367 4.69805H19.9895C21.0516 4.69805 21.9126 5.55902 21.9126 6.62111V6.62858H7.00279C5.72076 6.62858 4.59255 7.47473 4.23357 8.70549L2.67612 14.0454V4.70551C2.67612 3.64344 3.53711 2.78244 4.59919 2.78244ZM6.07972 9.24395C6.19939 8.8337 6.57544 8.55164 7.00279 8.55164H24.6364C25.2777 8.55164 25.7393 9.16743 25.5593 9.78293L23.311 17.4753C23.1912 17.8852 22.8152 18.167 22.3881 18.167H4.75922C4.11819 18.167 3.65665 17.5516 3.83615 16.9362L6.07972 9.24395Z"
                fill="#6F00FF" />
            </svg>
          </span>
          <div>
            <a [routerLink]="selectedProject?.id ? ['/dashboard/config/files/file-storage'] : null"
              class="btn stretched-link p-0 border-0" [class.disabled]="!selectedProject?.id"
              [style.pointer-events]="!selectedProject?.id ? 'none' : 'auto'">
              <h4 class="title mb-0">File storage</h4>
            </a>
            <p class="desc mb-0">
              A file storage is where you manage all your stuff
            </p>
          </div>
        </div>
      </div>
      <div class="card border-0 rounded-0">
        <div class="card-body">
          <span>
            <svg xmlns="http://www.w3.org/2000/svg" width="17" height="13" viewBox="0 0 17 13" fill="none">
              <path
                d="M0.823669 0.329199H0.723669V0.429199V12.6218V12.7218H0.823669H16.0645H16.1645V12.6218V0.429199V0.329199H16.0645H0.823669ZM10.1222 7.18756H1.4317V3.06933H10.1222V7.18756ZM1.4317 12.0138V7.89558H10.1222V12.0138H1.4317ZM15.4564 12.0138H10.8302V7.89558H15.4564V12.0138ZM15.4564 7.18756H10.8302V3.06933H15.4564V7.18756Z"
                fill="#6F00FF" stroke="white" stroke-width="0.2" />
            </svg>
          </span>
          <div>
            <a [routerLink]="selectedProject?.id ? ['/dashboard/config/data-tables'] : null"
              class="btn stretched-link p-0 border-0" [class.disabled]="!selectedProject?.id"
              [style.pointer-events]="!selectedProject?.id ? 'none' : 'auto'">
              <h4 class="title mb-0">Data tables</h4>
            </a>
            <p class="desc mb-0">
              Create data tables with data type and validation feature
            </p>
          </div>
        </div>
      </div>
      <div class="card border-0 rounded-0">
        <div class="card-body">
          <span>
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd"
                d="M3.63471 10.8528H5.61376C5.89671 10.8528 6.08503 10.6642 6.08503 10.3816C6.08503 10.099 5.8964 9.91033 5.61376 9.91033H3.63471C3.16376 9.91033 2.6925 9.81632 2.22123 9.7217C1.9386 9.62769 1.74997 9.53338 1.56165 9.43906C1.37303 9.34506 1.27871 9.06212 1.27871 8.8738C1.27871 8.68517 1.37303 8.49654 1.46734 8.30822C1.9386 7.74295 2.78681 7.36601 3.91765 6.98906C4.01197 6.98906 4.01197 6.89474 4.10628 6.89474C4.38892 6.70612 4.57724 6.42348 4.67155 6.14085C4.67155 5.76359 4.48292 5.48096 4.20029 5.29264C3.54071 4.35012 3.16376 3.50222 3.25776 2.74801C3.35208 2.27706 3.54071 1.90011 3.91765 1.61717C4.57724 1.14591 5.51945 1.14591 6.08534 1.61717C6.46197 1.90011 6.6506 2.27706 6.74492 2.74801C6.83892 3.50191 6.46197 4.35012 5.80239 5.29264C5.61376 5.48127 5.70808 5.7639 5.89671 5.95222C6.08534 6.14085 6.36797 6.04653 6.55629 5.85822C7.49881 4.72706 7.87576 3.59622 7.68713 2.55969C7.59282 1.90011 7.21587 1.24022 6.6506 0.863273C5.70808 0.109378 4.29429 0.109378 3.35208 0.863273C2.78681 1.33453 2.40986 1.90011 2.31555 2.55969C2.22124 3.59622 2.59818 4.72737 3.44639 5.85822C3.54071 5.95222 3.54071 6.04653 3.63502 6.04653C3.63502 6.04653 3.54071 6.04654 3.54071 6.14085C2.31555 6.5178 1.37303 7.08306 0.807759 7.74264C0.524816 8.02559 0.336496 8.49685 0.336496 8.96811C0.336484 9.2241 0.396081 9.47658 0.510565 9.70555C0.625049 9.93452 0.791276 10.1337 0.996078 10.2873C1.27871 10.4759 1.56165 10.6645 1.9386 10.7585C2.50387 10.7585 3.06944 10.8528 3.63471 10.8528Z"
                fill="#6F00FF" />
              <path fill-rule="evenodd" clip-rule="evenodd"
                d="M13.9068 10.8521H15.8859C16.5454 10.8521 17.1107 10.7578 17.582 10.5695C17.9592 10.4752 18.2419 10.2866 18.5245 10.0982C18.9014 9.8156 19.1841 9.25002 19.1841 8.77876C19.1841 8.3075 18.9955 7.93055 18.7128 7.5536C18.0532 6.89402 17.111 6.42276 15.8859 6.04581C15.8859 6.04581 15.7916 6.04581 15.7916 5.9515C15.7916 5.9515 15.8859 5.85749 15.8859 5.76287C16.8281 4.63265 17.299 3.40749 17.1104 2.46497C17.0164 1.80538 16.6395 1.14549 16.0739 0.768547C15.1314 0.0146512 13.7179 0.0146512 12.7754 0.768547C12.2101 1.23981 11.8331 1.80538 11.7388 2.46497C11.6445 3.50149 12.0215 4.63265 12.8697 5.76349C13.0583 5.95212 13.3409 5.95212 13.5292 5.8578C13.7179 5.66918 13.8122 5.38654 13.6236 5.19791C12.8697 4.2557 12.587 3.31318 12.6813 2.65359C12.6813 2.18233 12.964 1.80538 13.3409 1.52244C14.0005 1.05149 14.8487 1.05149 15.5086 1.52244C15.8856 1.80538 16.1682 2.18233 16.1682 2.55928C16.2625 3.31318 15.8856 4.16108 15.2257 5.1036C15.037 5.38654 14.7544 5.66918 14.8487 5.95181C14.8487 6.32907 15.1314 6.51739 15.4143 6.7057C15.4143 6.7057 15.5083 6.80002 15.6029 6.80002C16.6395 7.27128 17.3933 7.64823 17.9586 8.2138C18.0529 8.40243 18.1472 8.59075 18.1472 8.77907C18.1472 9.0617 18.0529 9.25033 17.8646 9.34465C17.676 9.43865 17.4874 9.53328 17.2047 9.62728C16.8278 9.81591 16.3565 9.90991 15.8856 9.90991H13.9065C13.6236 9.90991 13.4352 10.0985 13.4352 10.3812C13.4352 10.6638 13.6239 10.8524 13.9065 10.8524L13.9068 10.8521Z"
                fill="#6F00FF" />
              <path fill-rule="evenodd" clip-rule="evenodd"
                d="M7.7809 19.0507H11.6447C12.4929 19.0507 13.2468 18.9564 13.9067 18.6738C14.3776 18.4852 14.7546 18.2968 15.0375 18.0142C15.6028 17.7316 15.8857 17.0717 15.8857 16.4121C15.8857 15.8465 15.6971 15.3753 15.3202 14.9043C14.5663 14.0561 13.3411 13.3962 11.6447 12.8309L11.3621 12.5483L11.6447 12.2654C12.8699 10.6636 13.4351 9.24979 13.2468 7.93032C13.0582 7.08211 12.6815 6.32852 11.9276 5.76263C10.7025 4.91473 8.81743 4.91473 7.68659 5.76263C6.93269 6.32852 6.46143 7.08211 6.36743 7.93032C6.1788 9.24979 6.74437 10.7576 7.87522 12.2657C8.15785 12.5483 8.15785 12.6423 8.15785 12.6423C8.15785 12.6423 7.96922 12.7366 7.87522 12.8309C6.1788 13.3962 4.95364 14.0561 4.19975 14.9043C3.8228 15.2813 3.63417 15.8465 3.63417 16.4121C3.63417 17.0717 3.91711 17.7316 4.38837 18.1085C4.67101 18.3912 5.04796 18.5795 5.51922 18.7681C6.27311 18.9567 7.02701 19.0507 7.7809 19.0507ZM9.75996 5.95126C10.3255 5.95126 10.8908 6.13989 11.3621 6.42252C11.9276 6.79978 12.2103 7.36505 12.3046 8.02463C12.3986 9.06116 11.9273 10.2863 10.9851 11.6058C10.7025 11.8884 10.4195 12.1714 10.5139 12.6423C10.5139 13.0196 10.8911 13.3022 11.1734 13.5848C11.1734 13.5848 11.2677 13.6792 11.3621 13.6792C12.9639 14.1504 14.0007 14.81 14.7546 15.5639C14.8489 15.7525 14.9432 16.1295 14.9432 16.4121C14.9432 16.7891 14.7546 17.166 14.472 17.3543C14.2833 17.5429 13.9067 17.7316 13.5294 17.8256C12.9642 18.0142 12.3046 18.1085 11.6447 18.1085H7.7809C7.12132 18.1085 6.46143 18.0142 5.89616 17.8256C5.51891 17.7316 5.23627 17.5429 4.95364 17.3543C4.67101 17.166 4.48238 16.7891 4.48238 16.4121C4.48238 16.1295 4.57669 15.7525 4.85964 15.5639C5.51922 14.81 6.65006 14.2444 8.25186 13.6792C8.34648 13.6792 8.34648 13.5848 8.44049 13.5848C8.72343 13.3022 9.10037 13.0193 9.10037 12.6423C9.10037 12.2654 8.81743 11.8884 8.5348 11.6058C7.59227 10.3806 7.12132 9.06116 7.21533 8.02463C7.30964 7.36505 7.59258 6.89379 8.15785 6.42283C8.62911 6.13989 9.19438 5.95126 9.75996 5.95126Z"
                fill="#6F00FF" />
            </svg>
          </span>
          <div>
            <a [routerLink]="['/dashboard/config/users']" class="btn stretched-link p-0 border-0">
              <h4 class="title mb-0">Users & Groups</h4>
            </a>
            <p class="desc mb-0">
              Create user groups and manage user according to their task
            </p>
          </div>
        </div>
      </div>
      <div class="card border-0 rounded-0">
        <div class="card-body">
          <span>
            <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M3.72246 2.09831C3.87211 2.24822 3.87108 2.5169 3.71985 2.66522V2.66522C3.71879 2.66626 3.71773 2.66729 3.71666 2.66831C3.5661 2.81312 3.30494 2.81072 3.15762 2.66261V2.66261V2.66261C3.01029 2.51448 3.00838 2.25332 3.15336 2.10289C3.15428 2.10193 3.1552 2.10098 3.15613 2.10004V2.10004C3.30423 1.94903 3.57303 1.94861 3.72246 2.09831V2.09831V2.09831ZM5.1163 2.10008C5.26496 2.2509 5.26299 2.51691 5.11193 2.66532V2.66532C5.03548 2.74043 4.93489 2.78604 4.82802 2.79407C4.71822 2.79407 4.61291 2.75045 4.53527 2.67281C4.45763 2.59517 4.41402 2.48987 4.41402 2.38007C4.41402 2.27027 4.45763 2.16496 4.53527 2.08732C4.61291 2.00968 4.71822 1.96606 4.82802 1.96606C4.93599 1.97447 5.03741 2.02118 5.11399 2.09776C5.11477 2.09853 5.11554 2.09931 5.1163 2.10008ZM2.33507 2.1024C2.48058 2.25295 2.47864 2.51487 2.33072 2.66305V2.66305V2.66305C2.18281 2.81122 1.92097 2.8132 1.77029 2.66785C1.76939 2.66698 1.76849 2.6661 1.76759 2.66522V2.66522C1.61636 2.5169 1.61439 2.2509 1.76323 2.10018C1.76399 2.0994 1.76476 2.09863 1.76553 2.09786C1.76642 2.09698 1.7673 2.0961 1.76819 2.09523C1.91912 1.94702 2.18467 1.94902 2.33277 2.10004V2.10004C2.33354 2.10082 2.33431 2.10161 2.33507 2.1024Z"
                fill="#6F00FF" />
              <path
                d="M9.18258 11.7974H11.1463C11.3407 11.7974 11.5271 11.7202 11.6645 11.5828C11.8019 11.4454 11.8791 11.259 11.8791 11.0647V1.96175C11.8791 1.76742 11.8019 1.58104 11.6645 1.44362C11.5271 1.3062 11.3407 1.229 11.1463 1.229H1.33266C1.13833 1.229 0.951948 1.3062 0.814531 1.44362C0.677114 1.58104 0.599915 1.76742 0.599915 1.96175V11.0647C0.599915 11.259 0.677114 11.4454 0.814531 11.5828C0.951948 11.7202 1.13833 11.7974 1.33266 11.7974H6.2516M0.599915 3.52837H11.8806"
                stroke="#6F00FF" stroke-linecap="round" stroke-linejoin="round" />
              <path
                d="M6.16092 5.4021H3.15519C2.57042 5.4021 2.09637 5.87615 2.09637 6.46092V6.46092C2.09637 7.04569 2.57042 7.51974 3.1552 7.51974H5.44869"
                stroke="#6F00FF" stroke-linecap="round" stroke-linejoin="round" />
              <path
                d="M6.16092 5.4021H9.16739C9.75216 5.4021 10.2262 5.87615 10.2262 6.46092V6.46092C10.2262 7.04569 9.75216 7.51974 9.16739 7.51974H5.44869M2.09637 9.60367H10.2262"
                stroke="#6F00FF" stroke-linecap="round" stroke-linejoin="round" />
              <path
                d="M8.20533 11.7994C8.20044 11.9253 8.14701 12.0444 8.05623 12.1317C7.96545 12.219 7.84438 12.2678 7.71842 12.2678C7.59245 12.2678 7.47139 12.219 7.38061 12.1317C7.28983 12.0444 7.23639 11.9253 7.23151 11.7994C7.23639 11.6736 7.28983 11.5545 7.38061 11.4672C7.47139 11.3798 7.59245 11.3311 7.71842 11.3311C7.84438 11.3311 7.96545 11.3798 8.05623 11.4672C8.14701 11.5545 8.20044 11.6736 8.20533 11.7994Z"
                fill="#6F00FF" />
            </svg>
          </span>
          <div>
            <a [routerLink]="selectedProject?.id ? ['/dashboard/config/email-templates'] : null"
              class="btn stretched-link p-0 border-0" [class.disabled]="!selectedProject?.id"
              [style.pointer-events]="!selectedProject?.id ? 'none' : 'auto'">
              <h4 class="title mb-0">Email templates</h4>
            </a>
            <p class="desc mb-0">
              Create customised email templates with all the options and
              validations
            </p>
          </div>
        </div>
      </div>
      <div class="card border-0 rounded-0">
        <div class="card-body">
          <span>
            <svg width="20" height="15" viewBox="0 0 32 23" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M3.01571 8.63133H3.0163L24.1972 8.63133L24.1978 8.63133C24.4503 8.63103 24.6924 8.53059 24.8709 8.35205C25.0495 8.17351 25.1499 7.93143 25.1502 7.67895V7.67835V2.30496V2.30436C25.1499 2.05186 25.0494 1.80979 24.8709 1.63125C24.6923 1.45272 24.4502 1.35231 24.1977 1.35205H24.1972L3.0163 1.35205L3.01578 1.35205C2.76328 1.35231 2.52119 1.45272 2.34263 1.63125C2.16407 1.80979 2.06362 2.05186 2.06332 2.30437V2.30496L2.06332 7.67835L2.06332 7.67894C2.06362 7.93144 2.16405 8.17351 2.3426 8.35205L2.69615 7.9985L2.3426 8.35205C2.52114 8.53059 2.76321 8.63103 3.01571 8.63133ZM8.07819 21.5976H8.07878H29.2597H29.2603C29.5128 21.5973 29.7549 21.4968 29.9334 21.3183C30.1119 21.1398 30.2124 20.8977 30.2127 20.6452V20.6446V15.2712V15.2706C30.2124 15.0181 30.1119 14.776 29.9334 14.5975C29.7548 14.419 29.5127 14.3186 29.2602 14.3183H29.2597H8.07878H8.07826C7.82576 14.3186 7.58367 14.419 7.40511 14.5975C7.22655 14.776 7.1261 15.0181 7.1258 15.2706V15.2712L7.1258 20.6446L7.1258 20.6452C7.1261 20.8977 7.22653 21.1398 7.40508 21.3183C7.58362 21.4968 7.8257 21.5973 8.07819 21.5976ZM22.2247 3.84347C22.2247 3.85324 22.2209 3.86262 22.2139 3.86954C22.207 3.87645 22.1976 3.88033 22.1879 3.88033H5.02564C5.01586 3.88033 5.00649 3.87645 4.99957 3.86954C4.99266 3.86263 4.98878 3.85325 4.98878 3.84347C4.98878 3.83369 4.99266 3.82432 4.99957 3.81741C5.00649 3.81049 5.01586 3.80661 5.02564 3.80661H22.1879C22.1976 3.80661 22.207 3.81049 22.2139 3.81741C22.2209 3.82432 22.2247 3.8337 22.2247 3.84347Z"
                stroke="#6F00FF" />
            </svg>
          </span>
          <div>
            <a [routerLink]="selectedProject?.id ? ['/dashboard/config/workflows'] : null"
              class="btn stretched-link p-0 border-0" [class.disabled]="!selectedProject?.id"
              [style.pointer-events]="!selectedProject?.id ? 'none' : 'auto'">
              <h4 class="title mb-0">Workflow</h4>
            </a>
            <p class="desc mb-0">
              Create a workflow with the help of templates
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-confirmation-modal [title]="'Delete Project'" [message]="'Are you sure you want to delete this project?'"
  (onConfirm)="handleDeleteProject()" (onCancel)="closeConfirmationModal()">
</app-confirmation-modal>
<div class="menu-backdrop" [class.d-none]="!menuToshow || !showNavigation"></div>
<!-- side-content for workflow end here... -->