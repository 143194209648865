import {
  AuthApi, registerInput, resetInput, authenticateInput, resetContent,
  registerContent, authenticateContent, forgotInput, forgotContent
} from '../api/auth.api';
import { Injectable } from "@angular/core";
import { JwtService } from './jwt.service';
import { apiCallWrapper } from '../api/api.util';
import { NotificationsService } from 'angular2-notifications';
import { Observable } from 'rxjs';
import { IJWTPayload, IRegisterInputPayload } from '../model/auth.model';
import { IChangeHost, IChannel, IChannelMessage, IChannelMessageOutPut, ICreateChannel, ICreateMessage } from '../model/chat.model';
import { ChatApi } from '../api/chat.api';
import { IQueryFilter, QueryResult } from '../model/query.filter.class';

@Injectable()
export class ChatService {
  constructor(
    private chatApi: ChatApi,
    public notifications: NotificationsService,
  ) { }

  /**
   * @description Create new channel
   */
  public readonly createChannel = (payLoad: ICreateChannel): Observable<ICreateChannel> => {
    return apiCallWrapper(
      this.chatApi.createChannel(payLoad),
      {
        notificationsService: this.notifications,
        action: "Creating Channel"
      }
    )
  }

  /**
 * @description delete new channel
 */
  public readonly deleteChat = (id: string): Observable<ICreateChannel> => {
    return apiCallWrapper(
      this.chatApi.delete(id, ''),
      {
        notificationsService: this.notifications,
        action: "Creating Channel"
      }
    )
  }

  /**
 * @description List channel
 */
  public readonly list = (query: IQueryFilter): Observable<QueryResult<IChannel>> => {
    return apiCallWrapper(
      this.chatApi.list(query),
      {
        notificationsService: this.notifications,
        action: "List Channel"
      }
    )
  }

  /**
* @description List All Message of channel
*/
  public readonly getAllMessage = (id: string): Observable<IChannelMessageOutPut> => {
    return apiCallWrapper(
      this.chatApi.getAllMessage(id),
      {
        notificationsService: this.notifications,
        action: "Get Message"
      }
    )
  }

  /**
* @description send Message to channel
*/
  public readonly sendMessage = (payload: ICreateMessage): Observable<{ success: true }> => {
    return apiCallWrapper(
      this.chatApi.sendMessage(payload),
      {
        notificationsService: this.notifications,
        action: "Send Message"
      }
    )
  }

  /**
* @description Single Chat Create
*/
  public readonly singleChat = (payload: { projectId: string, message: string, userId: string }): Observable<any> => {
    return apiCallWrapper(
      this.chatApi.singleChat(payload),
      {
        notificationsService: this.notifications,
        action: "Send Message"
      }
    )
  }

  /**
* @description Change Chat Host
*/
  public readonly changeHost = (id, payload: IChangeHost): Observable<any> => {
    return apiCallWrapper(
      this.chatApi.changeHost(id, payload),
      {
        notificationsService: this.notifications,
        action: "Host Change"
      }
    )
  }

  /**
* @description Remove User From Chat
*/
  public readonly removeChatuser = (id, payload): Observable<any> => {
    return apiCallWrapper(
      this.chatApi.removeChatUser(id, payload),
      {
        notificationsService: this.notifications,
        action: "Remove User"
      }
    )
  }

  /**
* @description Invite User From Chat
*/
  public readonly inviteChatuser = (id, payload): Observable<any> => {
    return apiCallWrapper(
      this.chatApi.inviteChatUser(id, payload),
      {
        notificationsService: this.notifications,
        action: "Invite User"
      }
    )
  }
}