import { Component, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Projectservice, ProjectStatusService } from '../../services/project.service';
import { ICreateProject } from '../../model/project.model';
import { IQueryFilter } from '../../model/query.filter.class';
import { JwtService } from '../../services/jwt.service';
import { ConfirmationModalComponent } from 'src/app/ui/view/confirmation-modal.component';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./_menu.component.scss']
})
export class MenuComponent implements OnInit {
  @ViewChild(ConfirmationModalComponent) confirmationModal!: ConfirmationModalComponent;

  menuToshow = false
  cardToshow = false
  isAdmin = false;
  showNavigation: boolean;
  createWorkFlow: boolean;
  projects: ICreateProject[] = [];
  _selectedProjected: ICreateProject;
  activatedAccount: string

  showMenu() {
    if (!this.selectedProject?.id) {
      return
    }
    this.menuToshow = !this.menuToshow;
  }

  showcollabratorCard() {
    this.cardToshow = !this.cardToshow;
  }

  currentPath: string = ''

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    private projectService: Projectservice,
    private jwtService: JwtService,
    private projectStatusService: ProjectStatusService
  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.currentPath = event.url;
        this.router.events.subscribe(() => {
          const currentRoute = this.getCurrentRoute(this.route);
          this.createWorkFlow = currentRoute.snapshot.data['showMenu'];
          this.showNavigation = currentRoute.snapshot.data['showNavigation'] !== false;
        });
      }
    });

    this.getProjects();
  }


  ngOnInit(): void {
    this.monitorJwtData()
  }


  private monitorJwtData(): void {
    this.jwtService.currentJwtPayload$.subscribe({
      next: (data: any) => {
        if (data) {
          this.activatedAccount = data.account.id;
          this.isAdmin = data.user.isAdmin ? true : false
        }
      },
    });
  }

  get selectedProject(): ICreateProject {
    return this._selectedProjected;
  }

  set selectedProject(value: ICreateProject) {
    if (this._selectedProjected !== value) {
      this._selectedProjected = value;
      this.getProjectStatus(); // Call your function here
    }
  }

  handleSelectProject(project: ICreateProject): void {
    this.selectedProject = project;
    this.projectService.setSelectedProject(this.activatedAccount, project.id || '');
  }

  getProjects() {
    this.projectService.list(new IQueryFilter({ limit: 10 })).subscribe(() => { });

    this.projectService.projects$.subscribe(projects => {
      this.projects = projects;

      const selectedProjectId = this.projectService.getSelectedProject(this.activatedAccount);

      if (!selectedProjectId && projects[0]?.id) {
        this.projectService.setSelectedProject(this.activatedAccount, projects[0]?.id || '');
        this.selectedProject = projects[0];
      } else {
        this.selectedProject = projects.find(item => item.id === selectedProjectId) ?? projects[0];
      }
    });
  }


  private getCurrentRoute(route: ActivatedRoute): ActivatedRoute {
    while (route.firstChild) {
      route = route.firstChild;
    }
    return route;
  }


  getProjectStatus() {
    this.projectStatusService.list(new IQueryFilter({ limit: 10, filter: { projectId: this.selectedProject.id } })).subscribe(() => { });
  }

  openConfirmationModal() {
    this.confirmationModal.show();
  }

  closeConfirmationModal() {
    this.confirmationModal.hide();
  }


  handleDeleteProject() {
    this.projectService.deleteProject(this.selectedProject?.id || '').subscribe({
      next: () => this.closeConfirmationModal(),
      error: (err) => console.log("error on delete", err),
      complete: () => { window.location.reload() }
    })
  }

}
