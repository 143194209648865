import { createUrl } from "./api.util";
import { Observable, pipe } from 'rxjs';
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { IChangeHost, IChannelMessageOutPut, ICreateChannel, ICreateMessage } from "../model/chat.model";
import { GenericApi } from "./generic.api";


@Injectable()
export class ChatApi extends GenericApi<{}> {

  public path = 'chat';

  constructor(
    public readonly httpClient: HttpClient
  ) {
    super(httpClient);
  }

  readonly createChannel = (opts: ICreateChannel): Observable<ICreateChannel> => {
    return this.httpClient.post<ICreateChannel>(
      createUrl(this.path),
      opts
    );
  };


  readonly getAllMessage = (id: string): Observable<IChannelMessageOutPut> => {
    return this.httpClient.get<IChannelMessageOutPut>(
      createUrl(this.path, 'message', id)
    );
  };

  readonly sendMessage = (opts: ICreateMessage): Observable<{ success: true }> => {
    return this.httpClient.post<{ success: true }>(
      createUrl(this.path, 'message'),
      opts
    );
  };

  singleChat(payload: { projectId: string; message: string; userId: string; }) {
    return this.httpClient.post<{ success: true }>(
      createUrl(this.path, 'user', 'message'),
      payload
    );
  }

  readonly changeHost = (id: string, opts: IChangeHost): Observable<{ success: true }> => {
    return this.httpClient.put<{ success: true }>(
      createUrl(this.path, 'change-host', id),
      opts
    );
  };

  readonly removeChatUser = (id: string, payload: { userId: string; }): Observable<{ success: true }> => {
    return this.httpClient.put<{ success: true }>(
      createUrl(this.path, 'remove', id),
      payload
    );
  };

  readonly inviteChatUser = (id: string, payload: { userId: string; }): Observable<any> => {
    return this.httpClient.put<{ success: true }>(
      createUrl(this.path, 'invite', id),
      payload
    );
  };
}