import { Component, ElementRef, QueryList, ViewChildren, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OffcanvasService } from 'src/app/ui/service/offcanvas.service';
import { WorkflowTaskAssignComponent } from 'src/app/workflow-template/view/workflow-task-assign/workflow-task-assign.component';
import { ChangeTaskStatusService, MyTaskService, TaskBulkDeleteService, TaskBulkUpdateService, UserTaskService } from '../../services/account.service';
import { ICreateProjectStatus } from '../../model/project.model';
import { Projectservice, ProjectStatusService } from '../../services/project.service';
import { JwtService } from '../../services/jwt.service';
import { IQueryFilter } from '../../model/query.filter.class';
import { DialogService } from 'src/app/ui/service/dialog.service';
import { MoveTaskDialogComponent } from './move-task-dialog/move-task-dialog.component';

@Component({
  selector: 'app-my-task',
  templateUrl: './my-task.component.html',
  styleUrls: ['./_my-task.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MyTaskComponent {
  constructor(
    public offCanvasService: OffcanvasService,
    public dialog: DialogService,
    private router: Router,
    private route: ActivatedRoute,
    private myTaskService: MyTaskService,
    private projectStatusService: ProjectStatusService,
    private changeTaskStatusService: ChangeTaskStatusService,
    private projectService: Projectservice,
    private jwtService: JwtService,
    private userTaskService: UserTaskService,
    private taskBulkUpdateService: TaskBulkUpdateService,
    private taskBulkDeleteService: TaskBulkDeleteService,
  ) { }
  cardToshow = false
  taskData: any = [];
  selectedItems: string[] = [];
  skip: number = 0;
  limit: number = 10;
  order: string = "asc";
  filter: object = {};
  totalItems: number;
  pageSize: number = 10;
  totalPage: number;
  activatedAccount: any;
  startDate: string;
  endDate: string;
  isLoading: boolean = false;

  showShareCard() {
    this.cardToshow = !this.cardToshow;
  }

  // taskColors
  statuses: ICreateProjectStatus[] = [];

  getTaskColor(val: string) {
    return this.statuses.find(data => data.statusName == val)?.description || 'bg-gray-25';
  }

  ngOnInit() {
    this.monitorJwtData();
    this.getProjectStatuses();
  }
  onCheckboxChange(itemId: string, event: Event) {
    const checked = (event.target as HTMLInputElement).checked;

    if (checked) {
      this.selectedItems.push(itemId);
    } else {
      this.selectedItems = this.selectedItems.filter(id => id !== itemId);
    }

  }

  private monitorJwtData(): void {
    this.jwtService.currentJwtPayload$.subscribe({
      next: (data: any) => {
        if (data) {
          this.activatedAccount = data.account;
          let projectId = this.projectService.getSelectedProject(this.activatedAccount.id)
          this.query.filter = { projectId: projectId }
          if (projectId) {
            this.getTaskList();
          }
        }
      },
    });
  }

  query = new IQueryFilter({
    skip: this.skip,
    limit: this.limit,
    order: this.order,
    filter: {}
  });

  @ViewChildren('statusCheckbox') statusCheckboxes!: QueryList<ElementRef>;

  addAssignFillter(type: string) {
    if (type == 'assign') {
      this.query.filter.assignedTo = this.activatedAccount.id;
    }
    if (type == 'due') {
      this.query.filter.due = 'today';
    }
    if (type == 'start') {
      this.query.filter.startDate = this.startDate;
    }
    if (type == 'end') {
      this.query.filter.endDate = this.endDate;
    }
    if (type == 'clear') {
      this.statusCheckboxes.forEach((checkbox) => {
        (checkbox.nativeElement as HTMLInputElement).checked = false;
      });
      this.query.filter = { projectId: this.projectService.getSelectedProject(this.activatedAccount.id) };
    }
    this.getTaskList();
  }

  onStatusChange(status: any, event: any) {
    if (event.target.checked) {
      this.addAssignFilter(status);
    } else {
      this.removeAssignFilter(status);
    }
  }

  addAssignFilter(status: any) {
    if (!this.query.filter) {
      this.query.filter = {};
    }

    if (!this.query.filter.status) {
      this.query.filter.status = [];
    }

    this.query.filter.status.push(status.statusName);
    this.getTaskList();
  }

  removeAssignFilter(status: any) {
    if (!this.query.filter.status) {
      this.query.filter.status = [];
    }
    const index = this.query.filter?.statuses?.indexOf(status.statusName);
    if (index !== -1) {
      this.query.filter.status.splice(index, 1);
    }
    this.getTaskList();
  }


  getTaskList() {
    this.isLoading = true;
    if (this.query.filter.status && this.query.filter.status.length == 0) {
      delete this.query.filter.status;
    }
    this.router.navigate(["."], {
      relativeTo: this.route,
      queryParams: this.query,
      queryParamsHandling: "",
    });
    this.myTaskService.list(this.query).subscribe({
      next: (data) => {
        this.isLoading = false
        this.taskData = this.organizeTasks(data.rows);
      },
      complete: () => this.isLoading = false
    })
  }

  organizeTasks(taskData) {
    let parentTasks = taskData.filter(task => task.taskId === null);
    let childTasks = taskData.filter(task => task.taskId !== null);

    childTasks.sort((a, b) => a.taskId.localeCompare(b.taskId));

    let result: any[] = [];
    parentTasks.forEach(parent => {
      result.push(parent);
      result.push(...childTasks.filter(child => child.taskId === parent.id));
    });

    return result;
  }

  getProjectStatuses() {
    this.projectStatusService.projectStatus$.subscribe(statuses => {
      this.statuses = statuses;
    });
  }

  handleAddtask() {
    const dialogRef = this.offCanvasService.open(
      WorkflowTaskAssignComponent,
      { data: {}, position: 'end', widthClass: 'task-detail' },
    );

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.getTaskList();
      }
    });
  }

  getStatusBadgeClass(status) {
    if (status === 'On Track' || 'Completed') {
      return 'bg-green'
    } else if (status === 'At Risk' || 'Due') {
      return 'bg-orange'
    } else if (status === 'Off Track') {
      return 'bg-red'
    }
  }

  getPriorityBedgeClass(status) {
    let classObj = { Low: "bg-red-light", Medium: "bg-green-light", High: "bg-blue-light" }
    return classObj[status]
  }

  goToTask(id, taskId, workflowInstanceId, instanceType) {
    alert()
    this.router.navigate(['/dashboard/config/forms/task/' + id], { queryParams: { taskId, workflowInstanceId } });
  }

  editTask(id: string, subTask, isEdit) {
    const dialogRef = this.offCanvasService.open(
      WorkflowTaskAssignComponent,
      { data: { id: id, isSubTask: subTask, isTaskEdit: isEdit, taskType: 'task' }, position: 'end', widthClass: 'task-detail' },
    );

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.getTaskList();
        console.log("task result", result);
      }
    });
  }

  /**
* @description Updates the task status to 'Completed' and navigates to the task board.
*/
  updateTask(taskId, status, oldStatus) {
    let formData = {
      status: status
    };
    this.changeTaskStatusService.update(taskId, formData).subscribe({
      next: () => {
        this.userTaskService.getActivityLog(formData, { status: oldStatus }, this.activatedAccount, [], taskId);
        this.getTaskList();
      },
      error: (err) =>
        console.log(err),
    });
  }

  taskAdded() {
    this.getTaskList();
  }



  openMoveTaskPopup() {
    const dialogRef = this.dialog.open(
      MoveTaskDialogComponent,
      { data: { selectedTasks: this.selectedItems } },
      "modal-width-xl"
    );

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        console.log("update data result", result)
        const { project, task } = result;
        const data = {
          projectId: project,
          taskId: task != '' ? task : null
        };
        if (Object.keys(data).length) {
          this.updateBulkTask(data);
        }
      }
    });
  }

  updateBulkTask(updateData) {
    let payload: any[] = [];
    this.selectedItems.forEach(each => {
      payload.push({
        id: each,
        data: updateData
      })
    });
    this.taskBulkUpdateService.create(payload).subscribe({
      next: () => {
        this.getTaskList();
      },
      error: (err) =>
        console.log(err),
      complete: () => {
        this.selectedItems = []
      }
    })
  }


  deleteBulkTask() {
    this.taskBulkDeleteService.create(this.selectedItems).subscribe({
      next: () => {
        this.getTaskList();
      },
      error: (err) =>
        console.log(err),
      complete: () => { }
    })
  }

}
